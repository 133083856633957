import Vue from "vue";
import Vuex from "vuex";

import plpModule from "./plp.store";
import pdpModule from "./pdp.store";
import homepageModule from "./homepage.store";
import reviewsModule from "./reviews.store";
import accountModule from "./account.store";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    plp: plpModule,
    pdp: pdpModule,
    homepage: homepageModule,
    reviews: reviewsModule,
    account: accountModule
  }
});

export default store;
