import homepageRoutes from './homepage.routes';
import reviewsRoutes from './reviews.routes';
import accountRoutes from './account.routes';
import shopRoutes from './shop.routes';

export default [
  homepageRoutes,
  reviewsRoutes,
  accountRoutes,
  shopRoutes
];